import { Injectable } from '@angular/core';
import {BehaviorSubject, catchError, EMPTY, map, Observable, tap, throwError} from "rxjs";
import {RegdataModel} from "../models/registration/regdata.model";
import {ResponseModel} from "../models/response.model";
import {UserModel} from "../models/user.model";
import {HttpClient} from "@angular/common/http";
import {DetailsMapModel} from "../models/registration/detailsMap.model";
import {ConfigService} from "./config.service";

@Injectable({
  providedIn: 'root'
})
export class RegistrationService {

  BASE_URL:string;
  CASINO_ID: number;

  private _regData: BehaviorSubject<RegdataModel | null> = new BehaviorSubject<RegdataModel | null>(null)
  private _regStep: BehaviorSubject<number> = new BehaviorSubject<number>(1)

  private _token = new BehaviorSubject<string | null>(null);

  constructor(
    private http: HttpClient,
    private configs: ConfigService
  ) {
    this.BASE_URL = this.configs.apiUrl;
    this.CASINO_ID = this.configs.casinoId;

    try {
      const regToken = localStorage.getItem('regToken');
      if (regToken) {
        this._token.next(regToken);
      }
    } catch (error){
      console.warn('Error with localStorage:', error);
    }


  }

  get token(): BehaviorSubject<string | null> {
    return this._token;
  }

  resetRegSession(): void {
    //delete regToken
    try {
      const regToken = localStorage.getItem('regToken');
      if (regToken) {
        localStorage.removeItem('regToken')
      }
    } catch (error){
      console.warn('Error with localStorage:', error);
    }
    this._token.next(null);
    //delete data in RegObject
    this.saveData(null);
    //reset step to initial
    this.setStep(1)

  }


  get regData(): BehaviorSubject<RegdataModel | null> {
    return this._regData;
  }

  saveData(regData: RegdataModel | null) {
    this._regData.next(regData)
  }

  get regStep(): BehaviorSubject<number> {
    return this._regStep;
  }

  setStep(step: number){
    this._regStep.next(step)
  }


  //step 1: start registration and get the regToken
  create(userLog: { username: string, email: string, locale:string }): Observable<ResponseModel> {

    return this.http.post<ResponseModel>(`${this.BASE_URL}`, {"jsonrpc":"2.0","id":this.CASINO_ID,"method":"registration.create","params":userLog})
      .pipe(
        map( (response: ResponseModel) => {
          if ('error' in response){
            throw response;
          }
          return response
          }
        ),
        tap((response: ResponseModel) => {
          if ('result' in response && response.result) {
            const result = response.result as { token: string }
            if (result.token) {
              try {
                localStorage.setItem('regToken', result.token);
              } catch (error){
                console.warn('Error with localStorage:', error);
              }
              this._token.next(result.token)
            }

          }
        }),
        catchError((error) => {
          // console.error('error from service:', error)
          return throwError(()=> error)
        })
      )

  }

  //step 2: get details and send them to server with the regToken (regInterceptor)
  addDetails(userDetails: DetailsMapModel): Observable<ResponseModel> {

    return this.http.post<ResponseModel>(`${this.BASE_URL}`, {"jsonrpc":"2.0","id":this.CASINO_ID,"method":"registration.addDetails","params":{"details": userDetails}})
      .pipe(
        map( (response: ResponseModel) => {
            if ('error' in response){
              throw response;
            }
            return response
          }
        ),
        catchError((error) => {
          // console.error('error from regService:', error)
          if (error.error.message === 'Could not authorize request.' || error.status === 401){
            this.resetRegSession();
          }
          return throwError(()=> error)
        })
      )

  }

  finalize(trackingInfo: {bannerId?: number, tracker?: string} ): Observable<ResponseModel> {

    return this.http.post<ResponseModel>(`${this.BASE_URL}`, {"jsonrpc":"2.0","id":this.CASINO_ID,"method":"registration.finalize","params":{"bannerId": trackingInfo.bannerId, "tracker": trackingInfo.tracker}})
      .pipe(
        map( (response: ResponseModel) => {
            if ('error' in response){
              throw response;
            }
            return response
          }
        ),
        catchError((error) => {
          // console.error('error from regService:', error)
          if (error.error.message === 'Could not authorize request.' || error.status === 401){
            this.resetRegSession();
          }
          return throwError(()=> error)
        })
      )

  }

}
