import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {BehaviorSubject, Observable, tap} from "rxjs";

import {PromotionModel} from "../models/promotion.model";
import {ResponseModel} from "../models/response.model";
import {UserModel} from "../models/user.model";
import {ConfigService} from "./config.service";
import * as Sentry from "@sentry/browser";
import {TrackingHelperService} from "./tracking-helper.service";

@Injectable({
  providedIn: 'root'
})
export class UserService {

  // BASE_URL: string = environment.apiUrl;
  BASE_URL: string;
  CASINO_ID: number;

  private _userObject = new BehaviorSubject<UserModel | null>(null);


  constructor(
    private http: HttpClient,
    private configs: ConfigService,
    private trackingService: TrackingHelperService
  ) {
    this.BASE_URL = this.configs.apiUrl;
    this.CASINO_ID = this.configs.casinoId;
  }

  getUserInfo(): Observable<ResponseModel>{
    return this.http.post<ResponseModel>(`${this.BASE_URL}`, {"jsonrpc":"2.0","id": this.CASINO_ID,"method":"user.info","params":{}})
      .pipe(tap((response: ResponseModel) => {
        if ('result' in response && response.result) {
          const result = response.result as { user: UserModel }
          if (result.user){
            this.updateUser(result.user);

            Sentry.setUser({
              id: result.user.id,
              username: result.user.login,
              tracker: this.trackingService.getTracker() //user or undefined
            })
          }
          // console.log(this._userObject)
        } else if ('error' in response){
          throw new Error(response.error.message)
        }
      }))
  }

  // getUserPromos(): Observable<{result: {"promotions": PromotionModel[]}, jsonrpc: string, id: number}>{
  //   return this.http.post<{result: {"promotions": PromotionModel[]}, jsonrpc: string, id: number}>(`${this.BASE_URL}`, {"jsonrpc":"2.0","id": this.CASINO_ID,"method":"user.promotion.list","params":{}});
  // }



  get userObject(): BehaviorSubject<UserModel | null> {
    return this._userObject;
  }

  updateUser(user: UserModel | null): void {
    this._userObject.next(user);
  }
}
