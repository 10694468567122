import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from "@angular/common/http";
import {Injectable, Injector} from "@angular/core";
import {Observable, catchError, throwError} from "rxjs";
import {AuthService} from "../services/auth.service";


@Injectable()
export class AuthenticationInterceptor implements HttpInterceptor {

  constructor(private injector: Injector) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    let token: string | null;
    try {
      token = localStorage.getItem('sessionToken');
    } catch (error) {
      console.warn('Error accessing localStorage in interceptor:', error);
      //check token in the app: authService
      const authService = this.injector.get(AuthService); // Get AuthService using injector
      token = authService.token.value;
    }

    let request = req;

    if(token){
      request = req.clone({
        headers: req.headers.set('Authorization', `Bearer ${token}`)
      })

    }

    return next.handle(request)
      .pipe(
        catchError(err => {
        if(err.status === 401){
          if (token){
            //means that user session has expired, so we have to reset userSession
            const authService = this.injector.get(AuthService);
            authService.resetUserSession();
            return throwError( ()=> new Error("Invalid token")) //handle this error from components
          }
          // other 401 status code errors, ex invalid regToken - handled in service
          return throwError(err)
        }

        //other errors with different status codes
        return throwError(err)
        // return throwError(()=> new Error(`OOPS, something went wrong: ${err.status}, ${err.message}`))
      }));

  }

}
